:root {
  --theme-color: #fb6340 ;
}
body{
  font-family: Open Sans, sans-serif !important;
  margin: 0 !important;
  /* font-size: 1rem !important; */
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #525f7f !important;
  text-align: left !important;
  background-color: #f8f9fe !important;
}
.cursor-pointer{
  cursor: pointer;
}
.profile-img{
  height: 40px;
  max-height: 100%;
}

.btn{
  padding: 0.5rem 0.8rem;
}

.btn-icon-only{
  padding: 0;
}

/* error boundry start */
.error-boundary {
    display: flex;
    align-content: center;
    align-items: center;
    height: 100vh;
}
  
.error-boundary .error-boundary-text {
    font-size: 1.25rem;
    text-align: center;
}
/* error boundry end */
/* validation error */
.invalid-feedback.error{
  display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}
/* notification toast msg */
.notification-error h4{
  color: #fff;
}
.notification-container .notification .title{
  color: #fff;
}
body, #root, .main-layout{
    min-height: 100vh;
}
.page-content{
    min-height: 30rem;
}
.logo img {
    width: 50px
}
.fade.toast.show{
    z-index: 99999;
}
/* scroll to top button */
#scrollTopbutton {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-size: 20px;
    line-height: 35px;
    border-radius: 100%;
    padding:  0 0 0 5px;
  }
  #scrollTopbutton.show {
    opacity: 1;
    visibility: visible;
  }
/* shimmer effect */
.shimmer {
    background: #ced4da;
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    font-size: 50px;
  }
  
  @keyframes shimmer {
    100% {-webkit-mask-position:left}
  }
/* antd css */
.fixedColumn{
  width: 20px !important;
}
.ant-spin-dot-item{
  background-color: var(--theme-color) !important;
}
.ant-table-row{
  text-wrap: wrap;
}
.text-wrap-wrap{
  text-wrap: wrap;
}
/* antd on refresh column */
.ant-table-expanded-row-fixed{
  width: auto !important;
}
/* react select */
.form-group-select{
  margin-bottom: 1.5rem;
  color: #8898aa;
}
.custom-select-input .select__input-container, .custom-select-input .select__single-value, .custom-select .select__dropdown-indicator, .custom-select-input .select__indicator-separator, .custom-select-input .select__indicator, .custom-select-input .select__placeholder{
  color: #8898aa !important;
}
.custom-select-input .select__control{
  /* border: 1px solid rgba(145, 158, 171, 0.32) !important;
  box-shadow: unset !important; */
  width: 100%;
  height: calc(2.75rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7 !important;
  border-radius: 0.375rem;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
}
/* .custom-select-input .select__control--menu-is-open{
  border-color: var(--theme-color) !important;
  box-shadow: unset !important;
} */
.custom-select-input .select__menu{
  z-index: 20 !important;
}
.custom-select-input .select__control--is-disabled{
  background-color: #e9ecef;
}
.custom-select-input .select__option--is-focused, .custom-select-input .select__option--is-selected{
  background-color: var(--theme-color) !important;
  color: #fff;
}
.custom-select-input.is-invalid .select__control
{
  border-color: #dc3545 !important;
}
/* datepicker ******************/
.react-datepicker-popper{
  z-index: 2 !important;
}
/* datepicker range *************/
.react-datepicker__day--keyboard-selected{
  color: #fff !important;
  background-color: #fda190 !important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected:hover{
  background-color: var(--theme-color) !important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
  background-color: rgba(102, 51, 153, 0.5);
}
.datepicker-input-form .react-datepicker-wrapper{
  width: 100%;
}
/*********** tab layout ******/
.emp_tab .nav-pills .nav-link {
  color: var(--PrimaryRed);
}
  
.emp_tab .nav-pills .nav-link:hover {
  color: var(--SecondaryYellow);
}
  
.emp_tab .nav-tabs .nav-link.disabled {
  color: var(--PrimaryRed);
}
  
.emp_tab .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: var(--PrimaryRed);
}
.role-badges{
  text-transform: none !important;
  font-size: 15px !important;
  color: #fff;
  background-color: var(--PrimaryRed);
}
/*---------------------datatable component css--------------------------------*/
.rdt_TableCol, .rdt_TableRow{
  color: #858796 !important;
  font-size: 16px !important;
}
.rdt_TableBody .rdt_TableRow{
  border-bottom: 1px solid #e9ecef !important;
  font-size: 0.8125rem !important;
}
.rdt_TableHead .rdt_TableRow{
  border-bottom: unset !important;
}
.rdt_TableHeadRow .rdt_TableCol{
  font-weight: 600 !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef !important;
  border-top: 1px solid #e9ecef !important;
}
.rdt_TableHeadRow{
  background-color: #f6f9fc !important;
  color: #8898aa !important;
}
.rdt_Pagination{
  border-width: 2px !important;
}
/* ---border---*/
.rdt_Loading, .rdt_NoData{
  color: #858796;
  border-color: #e3e6f0;
  border-width: 0 0 1px 0px;
  border-style: solid;
  width: 100%;
  text-align: center;
}
.rdt_NoData{
  background-color: rgb(238, 238, 238);
  padding: 16px;
}
.rdt_TableCol{
  font-size:10.4px !important;
}

/*---------------------- The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  /* width: 60px;
  height: 34px; */
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  /* height: 26px;
  width: 26px; */
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  /* background-color: #2196F3; */
  background-color: var(--theme-color);
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #2196F3; */
  box-shadow: 0 0 1px var(--theme-color);
  
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*........................Bhaumik............................*/

.footerbox{
  position: fixed;
  width: calc(100% - 250px);
  left:250px;
  bottom: 0;
  box-shadow: 5px -2px 10px 0px #c0c0c036;
}
.footer {
  background: #FFF;
  padding: 0.3rem 0;
}

.header .card-stats .card-body {
	padding: 1rem 0.9rem;
}
.note-msg{
  font-size: .875em;
}
.table thead th,
.table td, 
.table th,
.dropdown-menu .dropdown-item,
.modal-title,
.form-control-label{
  font-size: 14px;
}

.table thead th.ant-table-cell:last-child{
  width: 110px;
}


@media only screen and (max-width: 767px) {

  .footerbox{
    
    width: 100%;
    left:0px;
  }

}

/* scrollbar======================css */
::-webkit-scrollbar{
  /* height: 4px;
  width: 4px;
  background: gray; */
  width: 5px;
  height: 5px;
  /* border-radius: 50px !important; */
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: var(--theme-color); 
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
background: #555; 
} */

/* Firefox */
html {
  scrollbar-color: var(--theme-color) white;
  scrollbar-width: auto;
}

::-webkit-scrollbar-thumb:horizontal{
  /* background: #000; */
  border-radius: 50px;
}
/* datepicker */
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker-popper{
  z-index: 2 !important;
}