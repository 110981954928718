@charset "utf-8";
/* CSS Document */

:root{
	--PrimaryRed:#c33f27;
	--PrimaryBlack:#231f20;
	--PrimaryWhite:#ffffff;
	--SecondaryYellow:#e99d28;
	--SecondaryGray:#828282;
	--PrimaryFonts:"Chica Gogo", Sans-serif;
}


/*Link*/
a {
  color: var(--PrimaryRed);
  
}

a:hover {
  color: var(--SecondaryYellow);
}




.page-link {
  
  color: var(--SecondaryYellow);
}

.page-link:hover {
  
  color: var(--PrimaryRed);
  
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--PrimaryRed);
  border-color: var(--PrimaryRed);
}

.page-item.disabled .page-link {
  color: #8898aa;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}




/*Backgrounds*/
.bg-default {
  background-color:var(--PrimaryRed) !important;
}
.bg-default-secondary {
  background-color:var(--SecondaryYellow) !important;
}

.bg-gradient-mix {
  background: linear-gradient(45deg, var(--PrimaryRed) 0, var(--SecondaryYellow) 100%) !important;
}
.bg-gradient-primary {
  background: linear-gradient(87deg, var(--SecondaryYellow) 0, var(--SecondaryYellow) 100%) !important;
}

.fill-default {
  fill: var(--PrimaryRed);
}

/*Buttons*/
.btn-primary {
  color:var(--PrimaryWhite);
  background-color:var(--PrimaryRed);
  border-color: var(--PrimaryRed);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-primary:hover {
  color:var(--PrimaryWhite);
  background-color:var(--PrimaryRed);
  border-color: var(--PrimaryRed);
  opacity: 0.9;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  
  background-color:var(--PrimaryRed);
  border-color: var(--PrimaryRed);
	opacity: 1;
}

/*Checkbox*/
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  border-color: var(--PrimaryRed);
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: var(--PrimaryRed);
}
.custom-control-input:active~.custom-control-label::before {
   background-color: var(--PrimaryRed);
}




/*radio*/




.custom-radio .custom-control-input:checked~.custom-control-label::before {
  border-color: var(--PrimaryRed);
	background-color: var(--PrimaryRed);
}


.custom-radio .custom-control-input:disabled~.custom-control-label::before {
  border-color: #e9ecef;
}

.custom-radio .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 1.5rem;
}

.custom-toggle input {
  display: none;
}

.custom-toggle input:checked+.custom-toggle-slider {
  border: 1px solid var(--PrimaryRed);
}

.custom-toggle input:checked+.custom-toggle-slider:before {
  background: var(--PrimaryRed);
  transform: translateX(1.625rem);
}

.custom-toggle input:disabled+.custom-toggle-slider {
  border: 1px solid #e9ecef;
  cursor: not-allowed;
}

.custom-toggle input:disabled:checked+.custom-toggle-slider {
  border: 1px solid #e9ecef;
  cursor: not-allowed;
}

.custom-toggle input:disabled:checked+.custom-toggle-slider:before {
  /* background-color: #8a98eb; */
  background-color: #e9ecef;
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #cad1d7;
  border-radius: 34px !important;
  background-color: transparent;
}

.custom-toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  border-radius: 50% !important;
  background-color: #e9ecef;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


.navbar-nav ul{padding: 0px; margin: 0px;}
.navbar-nav ul li a{font-size: 13px; color: #828282;}
.navbar-nav ul li a span{padding-left: 2px; padding-right: 20px;}
.dropdown-item.active, .dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: var(--PrimaryRed);
}

.file {
  visibility: hidden;
  position: absolute;
}
#preview{max-width: 100px; max-height: 100px;}
.dropdown-item.active {
	color: #16181b;
	text-decoration: none;
	background-color: #f6f9fc;
}